<template>
  <div class="">
    <el-form class="demo-form-inline" :inline="true" size="mini">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        @selection-change="selectNews"
        ref="rfTable"
        style="width: 100%;"
        size="mini">
      <el-table-column v-if="type == 2"
         type="selection"
         align="center"
         width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID"
          width="100">
      </el-table-column>
      <el-table-column
          prop="title"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="level"
          label="等级"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="discount"
          label="折扣"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="is_postage_free"
          label="是否包邮"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{ scope.row.is_postage_free == 1 ? '包邮' : '不包邮' }}
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column v-if="type == 1"
         fixed="right"
         label="选择"
         align="center"
         width="100">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="selectRow(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="changePage"
        layout="total, prev, pager, next"
        :page-size="search.limit"
        :total="search.total">
    </el-pagination>
    <el-button type="primary" size="small" @click="chooseOk()" style="position: absolute; bottom: 35px; left: 20px;" v-if="type == 2">确定</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        current_page: 1,
        total: 0,
        limit: 5,
        keywords: ''
      },
      tableData: [],
      chooseList: []
    }
  },
  components: {},
  props: {
    callFunc: Function,
    idx: Number,
    type: Number, // 1-单选，2-多选
    cardType: Number // 类型：0-全部，1-自动升级，2-手动升级
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        type: this.cardType
      }
      this.$api.member.memberGradeIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    changePage(page) {
      this.search.current_page = page
      this.getList()
    },
    selectRow(row) {
      this.callFunc(row)
    },
    selectNews(data) {
      this.chooseList = data
    },
    // 多选操作
    chooseOk() {
      if (this.chooseList.length < 1) {
        this.fail('请选择会员卡')
      } else {
        this.callFunc(this.chooseList)
      }
    }
  }
}
</script>

<style scoped>
</style>
