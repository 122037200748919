<template>
    <div class="box">
        <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
            <el-tabs v-model="tabIdx">
                <el-tab-pane label="基本信息" name="0"></el-tab-pane>
                <el-tab-pane label="规格库存" name="1"></el-tab-pane>
                <el-tab-pane label="物流运费" name="2" v-if="info.goods_type==1"></el-tab-pane>
                <el-tab-pane label="其他设置" name="3"></el-tab-pane>
            </el-tabs>
            <div v-if="tabIdx == 0" style="width: 800px">
                <el-form-item label="商品类型" prop="goods_type">
                    <el-radio-group v-model="info.goods_type" size="small" :disabled="info.id>0" @input="changeGoodsType">
                        <el-radio-button label="1" border>普通商品</el-radio-button>
                        <el-radio-button label="2" border>虚拟商品</el-radio-button>
<!--                        <el-radio-button label="3" border :disabled="true">卡密商品</el-radio-button>-->
                        <el-radio-button label="4" border>优惠券</el-radio-button>
                        <el-radio-button label="5" border>会员卡</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="商品名称" prop="title">
                    <el-input v-model="info.title" placeholder="请输入商品名称"></el-input>
                </el-form-item>
                <el-form-item label="分类" prop="menu_ids">
                    <el-select v-model="info.menu_ids" placeholder="请选择" multiple style="width: 100%">
                        <el-option
                            v-for="item in menuList"
                            :key="item.id"
                            :label="item.title"
                            :disabled="item.pid==0"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品图" prop="imgs">
                    <SelectImgs :selectNum="5" :selectData="imgs" :selectFunc="imgChoose" :rsyncFunc="rsyncFunc" :idx="1"></SelectImgs>
                    <span style="color: #ccc;">最多5张图，第一张图为默认图</span>
                </el-form-item>
                <el-form-item label="单位" prop="unit_name">
                  <el-input v-model="info.unit_name" placeholder="请输入单位"></el-input>
                  <div class="mg-tp-10">
                    <span class="c666">常用：</span>
                    <el-tag type="warning" class="pt mg-rt-5" v-for="(item,index) in tags" :key="index" @click="info.unit_name=item">{{ item }}</el-tag>
                  </div>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
                </el-form-item>
                <el-form-item label="详情" prop="content">
                    <wang-edit :txt="info.content" :callback="setText"></wang-edit>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="changeTab(2)">下一步</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                </el-form-item>
            </div>
            <div v-if="tabIdx == 1">
                <el-form-item label="规格">
                    <el-radio-group v-model="info.is_sku" :disabled="info.id > 0">
                        <el-radio :label="0">单规格</el-radio>
                        <el-radio :label="1">多规格</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="价格" v-if="info.is_sku==0">
                    <el-input v-model="info.price" placeholder="请输入价格">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="原价" v-if="info.is_sku==0">
                    <el-input v-model="info.old_price" placeholder="请输入价格">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
              <el-form-item label="成本价" v-if="info.is_sku==0">
                <el-input v-model="info.cost_price" placeholder="请输入价格">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="库存" v-if="info.is_sku==0">
                <el-input v-model="info.stock" placeholder="请输入库存" :disabled="info.id"></el-input>
              </el-form-item>
              <el-form-item label="商品编码" v-if="info.is_sku==0">
                  <el-input v-model="info.barcode" placeholder="请输入编码"></el-input>
              </el-form-item>
              <el-form-item label="重量(kg)" v-if="info.is_sku==0 && info.goods_type==1">
                  <el-input v-model="info.weight" placeholder="请输入重量"></el-input>
              </el-form-item>
              <el-form-item label="体积(m³)" v-if="info.is_sku==0 && info.goods_type==1">
                  <el-input v-model="info.volume" placeholder="请输入体积"></el-input>
              </el-form-item>
              <el-form-item label="卡密设置" v-if="info.is_sku==0 && info.goods_type==3" prop="relationship">
                <el-button type="text" size="small">设置卡密</el-button>
              </el-form-item>
              <el-form-item label="优惠券设置" v-if="info.is_sku==0 && info.goods_type==4" prop="relationship">
                <span v-if="info.relationship" class="mg-rt-10" style="font-size: 12px; color: #666">{{info.relationship.title}}</span>
                <el-button type="text" size="small" @click="goChooseCoupon(0)">选择优惠券</el-button>
              </el-form-item>
              <el-form-item label="会员卡设置" v-if="info.is_sku==0 && info.goods_type==5" prop="relationship">
                <span v-if="info.relationship" class="mg-rt-10" style="font-size: 12px; color: #666">{{info.relationship.title}}</span>
                <el-button type="text" size="small" @click="goChooseCard(0)">选择会员卡</el-button>
                <el-popover
                    class="mg-lf-5"
                    placement="top-start"
                    title="购买提示"
                    width="200"
                    trigger="hover"
                    content="默认购买一年，重复购买时间叠加">
                  <i class="el-icon-warning-outline" slot="reference"></i>
                </el-popover>
              </el-form-item>
                <el-form-item label="" v-if="info.is_sku==1">
                    <div class="mg-bt-5">
                        <el-select v-model="specsIdx" placeholder="请选择规格模板" class="mg-rt-5">
                            <el-option label="请选择规格模板" value="0"></el-option>
                            <el-option
                                    v-for="child in specsTempList"
                                    :key="child.id"
                                    :label="child.title"
                                    :value="child.id">
                            </el-option>
                        </el-select>
                        <el-button type="primary" @click="useTemp">使用该模板</el-button>
                    </div>
                    <div v-if="templateInfo && templateInfo.length > 0">
                        <div v-for="(item,index) in templateInfo" :key="index">
                            <div class="mg-bt-5">
                                <el-tag closable effect="dark" size="medium" @close="deleteMain(index)">{{ item.title }}</el-tag>
                            </div>
                            <el-tag class="mg-rt-10 mg-bt-5 fl" closable v-for="(child,index2) in item.txt" :key="index2" @close="deleteChild(index,index2)">{{ child }}</el-tag>
                            <span style="width: 200px; float: left;">
                              <el-input placeholder="请输入属性名称" class="font12" v-model="item.word">
                                <el-button type="primary" slot="append" class="iv-search" @click="saveChild(index)">添 加</el-button>
                              </el-input>
                            </span>
                            <div class="clr mg-bt-10"></div>
                        </div>
                    </div>
                    <el-button type="primary" size="small" icon="el-icon-plus" @click="showMenu()" v-if="!showInput && templateInfo.length < 3">添加新规格</el-button>
                </el-form-item>
                <el-form-item label="新增" v-if="showInput && info.is_sku==1">
                    <el-row>
                        <el-col :span="5" class="mg-rt-10">
                            <el-input v-model="specsInfo.title" autocomplete="off" placeholder="请输入规格名">
                                <template slot="prepend">规格名：</template>
                            </el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input v-model="specsInfo.value" autocomplete="off" placeholder="请输入规格值">
                                <template slot="prepend">规格值：</template>
                            </el-input>
                        </el-col>
                        <el-col :span="3" style="text-align: right">
                            <el-button type="primary" @click="saveMain" size="small">确 定</el-button>
                            <el-button @click="showInput = false" size="small">取 消</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="" v-if="info.is_sku==1">
                    <el-button type="primary" @click="createList">立即生成</el-button>
                    <el-table
                        :data="tableData"
                        :key="tableRandKey"
                        ref="rfTable"
                        border
                        style="margin-top: 10px;"
                        size="mini">
                        <el-table-column v-for="(item,index) in tableHeader" :key="index"
                             :prop="item.field"
                             :label="item.title"
                             align="center"
                             width="140">
                        </el-table-column>
                        <el-table-column
                            prop="img"
                            label="图片"
                            align="center"
                            min-width="120">
                            <template slot-scope="scope">
                                <SelectImgs :selectNum="1" :selectData="[scope.row.img]" :selectFunc="imgSpecChoose" :idx="scope.$index"></SelectImgs>
                                <el-input v-model="scope.row.rk" autocomplete="off" placeholder="请输入价格" style="display: none"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="price"
                            label="价格"
                            align="center"
                            width="140">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.price" autocomplete="off" placeholder="请输入价格"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="old_price"
                            label="原价"
                            align="center"
                            width="140">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.old_price" autocomplete="off" placeholder="请输入价格"></el-input>
                            </template>
                        </el-table-column>
                      <el-table-column
                          prop="cost_price"
                          label="成本价"
                          align="center"
                          width="140">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.cost_price" autocomplete="off" placeholder="请输入价格"></el-input>
                        </template>
                      </el-table-column>
                        <el-table-column
                            prop="stock"
                            label="库存"
                            align="center"
                            width="140">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.stock" autocomplete="off" placeholder="请输入库存" :disabled="info.id"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="barcode"
                                label="产品编码"
                                align="center"
                                width="140">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.barcode" autocomplete="off" placeholder="请输入编码"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="info.goods_type==1"
                                prop="weight"
                                label="重量(kg)"
                                align="center"
                                width="140">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.weight" autocomplete="off" placeholder="请输入重量"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="info.goods_type==1"
                                prop="volume"
                                label="体积(m³)"
                                align="center"
                                width="140">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.volume" autocomplete="off" placeholder="请输入体积"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="info.goods_type==3 || info.goods_type==4 || info.goods_type==5"
                            prop="volume"
                            :label="info.goods_type==3?'设置卡密':(info.goods_type==4?'设置优惠券':'设置会员卡')"
                            align="center"
                            width="120">
                          <template slot-scope="scope">
                            <div v-if="info.goods_type==3">
                              <el-button type="text" size="small">设置卡密</el-button>
                            </div>
                            <div v-if="info.goods_type==4">
                              <div v-if="scope.row.relationship">{{scope.row.relationship.title}}</div>
                              <el-button type="text" size="small" @click="goChooseCoupon(1,scope.$index)">选择优惠券</el-button>
                            </div>
                            <div v-if="info.goods_type==5">
                              <div v-if="scope.row.relationship">{{scope.row.relationship.title}}</div>
                              <el-button type="text" size="small" @click="goChooseCard(1,scope.$index)">选择会员卡</el-button>
                              <el-popover
                                  class="mg-lf-5"
                                  placement="top-start"
                                  title="购买提示"
                                  width="200"
                                  trigger="hover"
                                  content="默认购买一年，重复购买时间叠加">
                                <i class="el-icon-warning-outline" slot="reference"></i>
                              </el-popover>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="100">
                            <template slot-scope="scope">
                                <el-popconfirm
                                    title="确认删除该规格吗？"
                                    @confirm="deleteSpecs(scope.$index)"
                                >
                                    <el-button slot="reference" type="text" size="small">删除</el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item>
                    <el-button @click="changeTab(1)">上一步</el-button>
                    <el-button type="primary" @click="changeTab(2)">下一步</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                </el-form-item>
            </div>
            <div v-if="tabIdx == 2" style="width: 500px">
                <el-form-item label="运费设置" prop="shipment_type">
                    <el-radio-group v-model="info.shipment_type" size="small">
                        <el-radio label="0">固定运费</el-radio>
                        <el-radio label="1">运费模板</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" prop="postage" v-if="info.shipment_type == 0">
                    <el-input v-model="info.postage" placeholder="请输入运费">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="" prop="shipment_id" v-if="info.shipment_type == 1">
                    <el-select v-model="info.shipment_id" placeholder="请选择" style="width: 100%">
                        <el-option
                                v-for="item in shipmentList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="changeTab(1)">上一步</el-button>
                    <el-button type="primary" @click="changeTab(2)">下一步</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                </el-form-item>
            </div>
            <div v-if="tabIdx == 3" style="width: 500px">
                <el-form-item label="虚拟销量" prop="base_csale">
                    <el-input v-model="info.base_csale" placeholder="请输入销量">
                      <template slot="append">{{info.unit_name}}</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="是否限购" prop="is_limit">
                    <el-switch v-model="info.is_limit" active-value="1" inactive-value="0" active-text="关闭" inactive-text="开启"></el-switch>
                </el-form-item>
                <el-form-item label="限购数量" prop="limit_num" v-if="info.is_limit == 1">
                    <el-input v-model="info.limit_num" placeholder="请输入数量">
                      <template slot="append">{{info.unit_name}}</template>
                    </el-input>
                </el-form-item>
              <el-form-item label="参与会员折扣" prop="is_member_discount">
                <el-switch v-model="info.is_member_discount" active-value="1" inactive-value="0" active-text="关闭" inactive-text="开启"></el-switch>
              </el-form-item>
              <el-form-item label="参与送积分" prop="is_send_integral">
                <el-switch v-model="info.is_send_integral" active-value="1" inactive-value="0" active-text="关闭" inactive-text="开启"></el-switch>
              </el-form-item>
              <el-form-item label="参与推客推广" prop="is_guider" v-if="functionList.guider==1">
                <el-switch v-model="info.is_guider" active-value="1" inactive-value="0" active-text="关闭" inactive-text="开启"></el-switch>
              </el-form-item>
              <el-form-item label="自定义留言" prop="is_form">
                <el-switch v-model="info.is_form" active-value="1" inactive-value="0" active-text="关闭" inactive-text="开启"></el-switch>
                <div class="list" v-if="info.is_form==1">
                  <div class="item mg-tp-10" v-for="(item,index) in info.custom_form" :key="index">
                    <el-row :gutter="10">
                      <el-col :span="9">
                        <el-input v-model="item.title" placeholder="请输入标题"></el-input>
                      </el-col>
                      <el-col :span="9">
                        <el-select v-model="item.type" placeholder="请选择类型">
                          <el-option
                              v-for="item in fieldList"
                              :key="item.type"
                              :label="item.title"
                              :value="item.type">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="4">
                        <el-checkbox v-model="item.checked" true-label="1" false-label="0">必填</el-checkbox>
                      </el-col>
                      <el-col :span="2">
                        <el-button type="text" @click="delCustomForm(index)">删除</el-button>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div v-if="info.is_form==1 && info.custom_form.length<5">
                  <el-button type="text" @click="addCustomForm">添加表单</el-button>
                </div>
              </el-form-item>
              <el-form-item label="商品推荐" prop="recmd_type">
                <el-radio-group v-model="info.recmd_type" size="small">
                  <el-radio label="0">不推荐</el-radio>
                  <el-radio label="1">后台推荐</el-radio>
                  <el-radio label="2">自定义商品</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="" v-if="info.recmd_type==2">
                <el-button type="primary" @click="showGoodsModal=true">选择商品</el-button>
                <div class="goods-box">
                  <draggable v-model="info.goods" :options="bottomNavOptions">
                    <div class="item" v-for="(item,index) in info.recmd_goods" :key="index">
                      <img :src="item.img" class="img" />
                      <div class="name">{{ item.title }}</div>
                      <div class="close" title="删除商品" @click="delGoods(index)"><i class="el-icon-error"></i></div>
                    </div>
                  </draggable>
                </div>
              </el-form-item>
              <el-form-item>
                  <el-button @click="changeTab(1)">上一步</el-button>
                  <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
              </el-form-item>
            </div>
        </el-form>
      <el-dialog
          title="设置会员卡"
          :visible.sync="showCardModal"
          :destroy-on-close="true"
          width="850px">
        <member-card-list :type="1" :cardType="2" :callFunc="setChooseCard"></member-card-list>
      </el-dialog>
      <el-dialog
          title="设置优惠券"
          :visible.sync="showCouponModal"
          :destroy-on-close="true"
          width="850px">
        <coupon-list :type="1" :sendType="2" :callFunc="setChooseCoupon"></coupon-list>
      </el-dialog>
      <el-dialog
          title="选择商品"
          :visible.sync="showGoodsModal"
          :destroy-on-close="true"
          width="900px">
        <goods-list :type="2" :selectFunc="chooseGoods"></goods-list>
      </el-dialog>
    </div>
</template>
<script>

  import WangEdit from "../../components/WangEdit";
  import SelectImgs from '../../components/SelectImgs'
  import MemberCardList from "@/components/Member/MemberCardList";
  import CouponList from "@/components/Marking/CouponList";
  import draggable from 'vuedraggable';
  import goodsList from "@/components/Goods/GoodsList";
  import variable from "@/utils/variable";

  export default {
    data() {
      return {
        tabIdx: '0',
        tableHeader: [],
        tableData: [],
        menuList: [],
        specsTempList: [],
        specsIdx: '',
        templateInfo: [],
        imgs: [],
        kefu_qrcode: [],
        tableRandKey: 1,
        dateRange: ['',''],
        tags: ['件','只','个','头','台','斤','包','袋','箱','把','套'],
        info: {
          id: 0,
          goods_type: '1',
          base_csale: 0,
          postage: 0,
          is_guider: '',
          menu_ids: [],
          imgs: '',
          unit_name: '',
          intro: '',
          price: '',
          old_price: '',
          kefu_qrcode: '',
          stock: '',
          content: '',
          is_sku: 0,
          shipment_type: '0',
          is_member_discount: '',
          is_send_integral: '',
          is_form: 0,
          limit_num: '',
          shipment_id: '',
          custom_form: [],
          is_presale: '',
          presale_start_time: '',
          presale_end_time: '',
          presale_price: '',
          relationship: false,
          recmd_type: '',
          recmd_goods: []
        },
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          title: [
            {required: true, message: '请输入商品名称', trigger: 'blur'}
          ],
          imgs: [
            {required: true, message: '请选择商品图', trigger: 'blur'}
          ],
          menu_ids: [
            {required: true, message: '请选择分类', trigger: 'blur'}
          ],
          goods_type: [
            {required: true, message: '请选择商品类别', trigger: 'blur'}
          ],
          shipment_id: [
            {required: true, message: '请选择运费模板', trigger: 'blur'}
          ],
          presale_price: [
            {required: true, message: '请输入预售定金', trigger: 'blur'}
          ],
          relationship: [
            {required: true, message: '请选择优惠券', trigger: 'blur'}
          ],
          postage: [
            {required: true, message: '请输入运费', trigger: 'blur'},
            { validator: this.$validate.isInteger, trigger: 'blur' }
          ],
          base_csale: [
            {required: true, message: '请输入虚拟销量', trigger: 'blur'},
            { validator: this.$validate.isInteger, trigger: 'blur' }
          ],
          limit_num: [
            {required: true, message: '请输入限购数量', trigger: 'blur'},
            { validator: this.$validate.isIntegerNonZero, trigger: 'blur' }
          ]
        },
        showInput: false,
        specsInfo: {
          title: '',
          value: ''
        },
        fieldList: variable.fieldList,
        shipmentList: [],
        showCardModal: false,
        showCouponModal: false,
        showGoodsModal: false,
        showBoxType: '',
        showBoxIdx: '',
        bottomNavOptions: {
          group: {
            name: 'bottomNav',
            pull: true,
            put: true
          },
          sort: true,
          animation: 100
        },
        functionList: {
          guider: 0
        }
      };
    },
    created() {
      this.getFuncList()
      this.getMenuList()
      this.getshipmentList()
      this.getSpecsList()
      if (this.$route.query.id) {
        this.info.id = this.$route.query.id
        this.getInfo()
      }
    },
    mounted() {
    },
    components: {
      SelectImgs,
      WangEdit,
      MemberCardList,
      CouponList,
      draggable,
      goodsList
    },
    computed: {
    },
    methods: {
      changeTab(type) {
        var tabIdx = ''
        if(type==1) {
          tabIdx = --this.tabIdx
          if(this.info.goods_type!=1 && tabIdx==2) {
            --tabIdx
          }
        } else if(type==2) {
          tabIdx = ++this.tabIdx
          if(this.info.goods_type!=1 && tabIdx==2) {
            ++tabIdx
          }
        }
        this.tabIdx = String(tabIdx)
      },
      getFuncList() {
        this.$api.merchant.sysFunctionList({keys:'guider'},res=>{
          if(res.errcode==0) {
            this.functionList = res.data
          }
        })
      },
      changeGoodsType(e) {
        this.info.relationship = false
        this.tableData.filter(function (item) {
          item.relationship = false
        })
      },
      showMenu() {
        this.showInput = true
      },
      goChooseCoupon(type,idx) {
        this.showBoxType = type
        this.showBoxIdx = idx
        this.showCouponModal = true
      },
      setChooseCoupon(item) {
        if(this.showBoxType==0) {
          this.info.relationship = {id:item.id,title:item.title}
        } else {
          this.tableData[this.showBoxIdx].relationship = {id:item.id,title:item.title}
        }
        this.showCouponModal = false
      },
      goChooseCard(type,idx) {
        this.showBoxType = type
        this.showBoxIdx = idx
        this.showCardModal = true
      },
      setChooseCard(item) {
        if(this.showBoxType==0) {
          this.info.relationship = {id:item.id,title:item.title}
        } else {
          this.tableData[this.showBoxIdx].relationship = {id:item.id,title:item.title}
        }
        this.showCardModal = false
      },
      saveMain() {
        if (this.specsInfo.title == '') {
          this.fail('请输入规格名')
        } else if (this.specsInfo.value == '') {
          this.fail('请输入属性值')
        } else {
          this.templateInfo.push({title: this.specsInfo.title.trim(), txt: [this.specsInfo.value.trim()]})
          this.clrSpecs()
          this.showInput = false
        }
      },
      saveChild(index) {
        if (!this.templateInfo[index].word) {
          this.fail('请输入属性名称')
        } else if (this.templateInfo[index].txt.indexOf(this.templateInfo[index].word) > -1) {
          this.fail('该属性已存在')
        } else {
          this.templateInfo[index].txt.push(this.templateInfo[index].word.trim())
          this.templateInfo[index].word = ''
        }
      },
      deleteMain(index) {
        this.templateInfo.splice(index, 1)
      },
      deleteChild(index, index2) {
        this.templateInfo[index].txt.splice(index2, 1)
      },
      clrSpecs() {
        this.specsInfo = {title: '', value: ''}
      },
      setText(txt) {
        this.info.content = txt
      },
      imgChoose(item, idx) {
        var _this = this
        if (idx == 1) {
          var pics = this.imgs
          item.forEach(function (child) {
            if (_this.imgs.length < 5) {
              pics.push(child.pic)
            }
          })
          this.imgs = pics
          this.info.imgs = pics
        } else {
          this.kefu_qrcode = [item.pic]
          this.info.kefu_qrcode = item.pic
        }
      },
      imgSpecChoose(item, idx) {
        this.tableData[idx].img = item.pic
        this.tableData[idx].rk = Math.random()
      },
      deleteSpecs(idx) {
        this.tableData.splice(idx, 1)
      },
      rsyncFunc(imgs) {
        this.imgs = imgs
        this.info.imgs = imgs
      },
      getMenuList() {
        this.$api.goods.menuIndex({page_size: 100,type:1}, res => {
          if (res.errcode == 0) {
            this.menuList = res.data
          }
        })
      },
      getshipmentList() {
        this.$api.goods.shipmentIndex({page_size: 100}, res => {
          if(res.errcode == 0) {
            this.shipmentList = res.data.data
          }
        })
      },
      getSpecsList() {
        this.$api.goods.specsTemplateIndex({page_size: 100}, res => {
          if (res.errcode == 0) {
            this.specsTempList = res.data.data
          }
        })
      },
      useTemp() {
        var _this = this
        _this.showInput = false
        this.specsTempList.forEach(function (item) {
          if (item.id == _this.specsIdx) {
            _this.templateInfo = JSON.parse(JSON.stringify(item.content))
          }
        })
      },
      createList() {
        var _this = this
        var oldData = JSON.parse(JSON.stringify(this.tableData))
        var tableHeader = []
        var tableBody = []
        var tableData = []
        if (_this.templateInfo) {
          _this.templateInfo.forEach(function (item, index) {
            tableHeader.push({title: item.title, field: 'value' + index})
            tableBody.push(item.txt)
          })
        }
        this.fs(tableBody).forEach(function (item, index) {
          var info = {price: '', old_price: '', img: '', stock: '', rk: '', txt: []}
          if(item instanceof Array) {
            item.forEach(function (child,cindex) {
              eval("info.value"+cindex+"='"+child+"'")
              info.txt.push(child.trim())
            })
          } else {
            info.txt = [item]
            info.value0 = item
          }
          info.relationship = false
          tableData.push(info)
        })
        tableData.forEach(function (item) {
          var info = _this.compareArr(item.txt,oldData)
          if(info) {
            item.img = info.img
            item.price = info.price
            item.old_price = info.old_price
            item.stock = info.stock
            item.barcode = info.barcode
            item.weight = info.weight
            item.volume = info.volume
          }
        })
        _this.tableHeader = tableHeader
        _this.tableData = tableData
      },
      compareArr(arr1,arr2) {
        for(var i=0; i<arr2.length; i++) {
          if(arr1.join(',') == arr2[i].txt.join(',')) {
            return arr2[i]
          }
        }
        return null
      },
      fs(array) {
        if (array.length < 2) return array[0] || [];
        return array.reduce((total, currentValue) => {
          let res = [];
          total.forEach(t => {
            currentValue.forEach(cv => {
              if (t instanceof Array) 	// 或者使用 Array.isArray(t)
                res.push([...t, cv]);
              else
                res.push([t, cv]);
            })
          })
          return res;
        })
      },
      getInfo() {
        var that = this
        this.$api.goods.goodsInfo({id: that.info.id}, function (res) {
          if (res.errcode == 0) {
            that.info = res.data
            if (that.info.imgs) {
              that.imgs = that.info.imgs
            }
            that.info.shipment_type = String(that.info.shipment_type)
            that.info.is_limit = String(that.info.is_limit)
            that.info.is_send_integral = String(that.info.is_send_integral)
            that.info.is_member_discount = String(that.info.is_member_discount)
            that.info.is_presale = String(that.info.is_presale)
            that.info.is_form = String(that.info.is_form)
            that.info.is_guider = String(that.info.is_guider)
            if (that.info.attrList) {
              that.templateInfo = that.info.attrList
              that.tableHeader = []
              that.templateInfo.forEach(function (item, index) {
                that.tableHeader.push({title: item.title, field: 'value' + index})
              })
            }
            if (that.info.specsList) {
              that.tableData = that.info.specsList
            }
            if(that.info.shipment_id == 0) {
              that.info.shipment_id = ''
            }
            if(that.info.is_presale==1) {
              that.dateRange = [that.info.presale_start_time,that.info.presale_end_time]
            }
            that.info.recmd_type = String(that.info.recmd_type)
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (that.info.is_sku == 1 && that.tableData.length < 1) {
              that.fail('请创建商品规格')
              return false
            }
            var param = this.info
            if (param.is_sku == 1) {
              param.specsList = that.tableData
              param.attrList = that.templateInfo
            }
            if(this.info.is_presale==1) {
              this.info.presale_start_time = this.dateRange[0]
              this.info.presale_end_time = this.dateRange[1]
            }
            var goodsIds = []
            if(this.info.recmd_type==2) {
              this.info.recmd_goods.forEach(function (item) {
                goodsIds.push(item.id)
              })
              param.recmd_goods_ids = goodsIds
            }
            if (this.info.id == 0) {
              this.$api.goods.goodsAdd(param, function (res) {
                if (res.errcode == 0) {
                  that.success(res.errmsg)
                  that.closePage()
                } else {
                  that.fail(res.errmsg)
                }
              })
            } else {
              this.$api.goods.goodsEdit(param, function (res) {
                if (res.errcode == 0) {
                  that.success(res.errmsg)
                  that.closePage()
                } else {
                  that.fail(res.errmsg)
                }
              })
            }
          } else {
            that.fail('请完善表单后提交')
            return false;
          }
        });
      },
      closePage() {
        setTimeout(function () {
          window.close()
        },500)
      },
      addCustomForm() {
        this.info.custom_form.push({
          title: '',
          type: '',
          checked: 0
        })
      },
      delCustomForm(idx) {
        this.info.custom_form.splice(idx,1)
      },
      chooseGoods(goods) {
        var _this = this
        goods.forEach(function (item) {
          _this.info.recmd_goods.push({id: item.id, title: item.title, img: item.img, price: item.price})
        })
        _this.showGoodsModal = false
      },
      delGoods(idx) {
        this.info.recmd_goods.splice(idx,1)
      },
    }
  };
</script>
<style scoped>
    .box {
        background-color: #fff;
        padding: 20px;
        border-radius: 6px;
        min-height: 500px;
    }
</style>
